import { EventEmitter, Injectable } from "@angular/core";

import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";
import { AppModes } from "./app-modes";
import { AppState } from "./app-state";
import { ViewModes } from "./view-modes";

/**
 * The service to change states, like the in-audit-mode or the switch between mobile and desktop.
 */
@Injectable({
    providedIn: "root"
})
export class AppService {
    constructor(
        private readonly storageService: StorageService
    ) {
        this.construct();
    }

    public readonly currentVersion: string = "2.26.1";

    public previousVersion?: string;

    public showUpdateHistory: boolean = false;

    public appLoaded: boolean = false;

    public appState: AppState = new AppState();

    public leftHanded: boolean = false;

    private alerter?: (title: string, message: string) => Promise<string|undefined>;

    public appLoadingFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

    public leftHandedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public focusModeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public followModeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    private pwaInstallPromptEvent?: any;

    public get needsRestart(): boolean {
        return this.appState.frontendUpdateAvailable;
    }

    public injectAlerter(alerter: (title: string, message: string) => Promise<string|undefined>): void {
        this.alerter = alerter;
    }

    private construct(): void {
        try {
            window.addEventListener("beforeinstallprompt", this.pwaStatusUpdate.bind(this));
        } catch (error) {
            // Ignore all errors, PWA features are not available.
        }
    }

    public async initialize(): Promise<void> {
        this.leftHanded = await this.storageService.get(StorageKeys.leftHandedMode, false) ?? false;

        const storedVersion: string|undefined = await this.storageService.get(StorageKeys.storedVersion);
        this.previousVersion = await this.storageService.get(StorageKeys.previousVersion);

        if (this.currentVersion != storedVersion) {
            console.info(`Previous version: ${storedVersion}`);
            this.showUpdateHistory = !!storedVersion;
            this.previousVersion = storedVersion;
            await this.storageService.set(StorageKeys.storedVersion, this.currentVersion);
            await this.storageService.set(StorageKeys.previousVersion, this.previousVersion);
        }
    }

    public async restartApp(showInfoMessage: boolean): Promise<void> {
        if (showInfoMessage && this.alerter) {
            if (this.appState.frontendUpdateAvailable) {
                await this.alerter($localize`:@@cloudStatus.clientVersionChangedTitle:New app version`, $localize`:@@app.clientVersionChangedMessage:The app has been updated. In order to prevent any issues, the app is going to restart now.`);
            } else if (this.appState.backendUpdateAvailable) {
                await this.alerter($localize`:@@cloudStatus.serverVersionChangedTitle:New server version`, $localize`:@@app.serverVersionChangedMessage:The server version has been changed from ${this.appState.oldBackendVersion}:oldVersion: to ${this.appState.newBackendVersion}:newVersion:. In order to prevent any issues, the app is going to restart now`).then();
            }
        }

        location.reload();
    }

    public notifyAppCompletelyInitialized(): void {
        console.info("Application has been initialized.");
        this.appLoaded = true;
        this.appLoadingFinished.emit(true);
    }

    public setAppMode(appMode: AppModes): void {
        this.appState.appMode = appMode;
    }

    public setViewMode(viewMode: ViewModes): void {
        this.appState.viewMode = viewMode;
    }

    public setFocusMode(enabled: boolean): void {
        this.appState.focusMode = enabled;
        this.focusModeChanged.emit(enabled);
    }

    public setFollowMode(enabled: boolean): void {
        this.appState.followMode = enabled;
        this.followModeChanged.emit(enabled);
    }

    public setLeftHandedMode(leftHanded: boolean): void {
        this.leftHanded = leftHanded;
        this.leftHandedChanged.emit(leftHanded);

        this.storageService.set(StorageKeys.leftHandedMode, this.leftHanded).then();
    }

    public notifyBackendUpdateAvailable(oldVersion: string, newVersion: string): void {
        console.info("Server update", oldVersion, newVersion);
        this.appState.backendUpdateAvailable = true;
        this.appState.oldBackendVersion = oldVersion;
        this.appState.newBackendVersion = newVersion;
    }

    public notifyFrontendUpdateAvailable(): void {
        this.appState.frontendUpdateAvailable = true;
    }

    private pwaStatusUpdate(event: Event): void {
        this.pwaInstallPromptEvent = event;
        this.appState.pwaInstalled = !event;
    }

    public installPwa(): void {
        if (this.appState.pwaInstalled || !this.pwaInstallPromptEvent?.prompt) {
            return;
        }

        this.pwaInstallPromptEvent.prompt();
        if ("userChoice" in this.pwaInstallPromptEvent && this.pwaInstallPromptEvent.userChoice.then) {
            this.pwaInstallPromptEvent.userChoice.then((choiceResult: any) => {
                this.appState.pwaInstalled = choiceResult?.outcome == "accepted";
            });
        }
    }
}
