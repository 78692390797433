<ion-header class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title i18n="@@about.dialogTitle">About</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="modalContent ion-padding">
    <div class="ion-padding">
        <img (click)="logoClick()" alt="logo" class="aboutLogo" height="100" ngSrc="assets/logos/cyanaudit-logo.svg" width="521">
    </div>
    <ion-list>
        <ion-item>
            <ion-label>
                <h2>CyanAudit</h2>
                <p class="ion-text-wrap">
                    <ng-container i18n="@@about.version">Version:</ng-container>&nbsp;2.26.1
                    <ng-container *ngIf="'2023-09-09T10:45:09Z'[0] != '#'">({{'2023-09-09T10:45:09Z' | date:'short'}})</ng-container>
                    <ng-container *ngIf="serverVersion"><br />
                        <ng-container i18n="@@about.serverVersion">Server Version:</ng-container>&nbsp;{{serverVersion}}</ng-container>
                    <ng-container *ngIf="serverBuildTime">&nbsp;({{serverBuildTime | date:'short'}})</ng-container>
                </p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.expirationDate">Software Expiration Date</h2>
                <p class="ion-text-wrap">
                    <ng-container *ngIf="'2024-09-03T23:59:59Z'[0] != '#'">{{'2024-09-03T23:59:59Z' | date:'short'}}</ng-container>
                </p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.productWebsite">Product Website</h2>
                <a (click)="openLink($event)" href="https://cyanaudit.com/">cyanaudit.com</a>
            </ion-label>
        </ion-item>

        <ion-item-divider>
            <ion-label>
                <h2 i18n="@@about.tmgHeadline">Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
            </ion-label>
        </ion-item-divider>

        <ion-item>
            <ion-label>
                <h2 i18n="@@about.company">Company</h2>
                <p class="ion-text-wrap">CyanCor GmbH</p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.companyWebsite">Company Website</h2>
                <a (click)="openLink($event)" href="https://cyancor.com/">cyancor.com</a>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.address">Address</h2>
                <p class="ion-text-wrap">Innstr. 71, 83022 Rosenheim, Deutschland </p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.telephoneNumber">Phone Number</h2>
                <a (click)="openLink($event)" href="tel:+4980317966921">+49 8031 79669 21</a>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.email">E-Mail</h2>
                <base-email [chunkPositions]="emailChunkPositions" [chunks]="emailChunks"></base-email>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.commercialRegister">Commercial Register</h2>
                <p class="ion-text-wrap">Traunstein District Court, Germany</p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.registerNumber">Register Number</h2>
                <p class="ion-text-wrap">HRB 23504</p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.vatId">VAT-ID</h2>
                <p class="ion-text-wrap">DE294722623</p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.representedBy">Represented by</h2>
                <p class="ion-text-wrap">Thomas Riedl,&nbsp;<ng-container i18n="@@about.managingDirector">Managing Director</ng-container>
                </p>
                <p class="ion-text-wrap">Michael Loeffl,&nbsp;<ng-container i18n="@@about.authorisedOfficer">Authorized Officer</ng-container>
                </p>
                <p class="ion-text-wrap">Simon Grießer,&nbsp;<ng-container i18n="@@about.authorisedOfficer">Authorized Officer</ng-container>
                </p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.EuDisputeResolution">EU dispute resolution</h2>
                <p class="ion-text-wrap" i18n="@@about.EuDisputeResolutionText">The European Commission provides a platform for online dispute resolution (ODR): https://ec.europa.eu/consumers/odr/. Our e-mail address can be found above in the site notice.</p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <h2 i18n="@@about.disputeResolutionConsumerArbitrationBoard">Dispute resolution proceedings in front of a consumer arbitration board</h2>
                <p class="ion-text-wrap" i18n="@@about.disputeResolutionConsumerArbitrationBoardText">We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.</p>
            </ion-label>
        </ion-item>
        <ion-buttons class="additionalActions">
            <ion-button (click)="openPrivacyPolicy()" color="primary" fill="outline">
                <ng-container i18n="@@about.privacyPolicy">Privacy policy</ng-container>
            </ion-button>
            <ion-button (click)="openFirstContactPrivacyPolicy()" color="primary" fill="outline">
                <ng-container i18n="@@about.firstContactPrivacyPolicy">First contact privacy policy</ng-container>
            </ion-button>
        </ion-buttons>
        <ion-buttons class="additionalActions">
            <ion-button (click)="openSoftwareLicenses()" color="primary" fill="outline">
                <ng-container i18n="@@about.softwareLicenses">Software licenses</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-list>
</ion-content>

<ion-footer class="modalFooter ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <fa-icon [icon]="appIcons.genericClose"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericCloseButton">Close</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
