/* eslint-disable @typescript-eslint/naming-convention */
// noinspection DuplicatedCode

import { EnvironmentInterface } from "./environment-interface";

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const environment: EnvironmentInterface = {
    production: true,

    apiBasePath: "https://p1.cyanaudit.com/audit-backend/v1",
    manualBaseUrl: "https://wiki.cyanaudit.com",

    auth: {
        clientId: "cyanaudit-frontend",
        issuer: "https://auth.cyancor.com/realms/cyanaudit",

        redirectUri: `${window.location.origin}/login`,
        responseType: "code",
        scope: "openid profile email offline_access",
        disableAtHashCheck: true,
        showDebugInformation: false,
        requireHttps: true,
        useSilentRefresh: false,
        timeoutFactor: 0.75
    },

    featureGpt: false,

    maxErrorReportsPerSession: 25000,
    errorReportingInterval: 60 * 1000,

    loginStillExistsCheckInterval: 15 * 1000,
    removeDataOnLogoutByDefault: false,

    answerUpdateDelay: 2000,
    maxAnswerBatchesToSend: 5,
    attachmentFailedUploadDelay: 5000,
    attachmentSuccessfulUploadDelay: 100,
    onlineCheckDelay: 20000,
    onlineCheckTimeout: 7000,
    appUpdateCheckInterval: 2 * 60000,
    postAppInitDelay: 30 * 1000,

    documentBackgroundUpdateInterval: 60 * 1000,
    documentLoadTimeout: 30 * 1000,

    searchDefaultResultsCount: 50,
    searchInputDebounce: 500,

    imagePreviewSize: 300,
    imageDefaultPrintQuality: 0.9,
    imagePrintQualityLow: 0.4,
    imagePrintQualityMedium: 0.7,
    imagePrintQualityHigh: 0.9,
    imagePrintQualityHighest: 1.0,
    cameraImageQuality: 85,
    modifiedImageQuality: 95,
    cameraMaxSize: 2000,

    passwordMinLength: 8,
    invitationCodeMinLength: 5,

    // See https://www.email-encoder.com/ how to generate these arrays.
    supportEmailChunks: ["aA", "eQ", "dQ", "Lg", "dA", "Pg", "Og", "bQ", "YQ", "QA", "eQ", "cA", "dA", "cg", "YQ", "aQ", "cA", "cg", "bA", "Lg", "dA", "bg", "YQ", "aQ", "Yw", "Ig", "dQ", "ZQ", "Yw", "ZA", "cw", "Pg", "IA", "bw", "Yw", "dQ", "cA", "cg", "bQ", "Yw", "YQ", "YQ", "QA", "aQ", "ZA", "dQ", "bw", "YQ", "Zg", "PQ", "bw", "dA", "Lw", "PA", "Ig", "bw", "dA", "bg", "cA", "PA", "cw", "YQ", "bw", "bQ"],
    supportEmailChunkPositions: [3, 48, 29, 33, 22, 63, 15, 59, 26, 23, 25, 19, 13, 21, 62, 54, 41, 44, 12, 56, 45, 27, 28, 11, 34, 8, 40, 5, 57, 30, 39, 38, 2, 35, 24, 52, 42, 4, 36, 47, 1, 51, 46, 31, 53, 17, 58, 49, 6, 7, 20, 32, 61, 0, 37, 14, 55, 50, 18, 60, 16, 10, 43, 9],

    setFocusDelay: 150,

    mxGraph: {
        basePath: "assets/mxGraph",
        mxGraphSourcePath: "mxGraphSources",
        languages: ["de-DE", "en-US"],
        imagePath: "images",
        resourcePath: "resources",
        resourceBase: "grapheditor",
        stencilPath: "stencils",
        stylePath: "styles"
    },

    additionalDatabaseName: "additionalDatabase",
    fileStorageName: "fileStorage",

    networkTestIpAddressUrl: "https://ip.cyanaudit.com/",
    networkTestGoogleUrl: "https://cyanaudit-network-test.web.app/status.json",
    networkTestDownload10KibUrl: "https://cyanaudit-network-test.web.app/testfile-10kib.dat",
    networkTestDownload100KibUrl: "https://cyanaudit-network-test.web.app/testfile-100kib.dat",

    formSettings: {
        lengthLimitDisplayPercent: 0.75,
        address: {
            maxLengthStreet: 200,
            maxLengthStreetNumber: 200,
            maxLengthZipCode: 200,
            maxLengthCity: 200,
            maxLengthProvince: 200,
            maxLengthAdditionalInfo: 2000
        },
        building: {
            maxLengthName: 500,
            maxLengthBuildingUsage: 200,
            maxLengthLocationHouseConnection: 200,
            maxLengthDrinkingWaterHeatingLocation: 500,
            maxLengthAdditionalInfo: 2000,
            maxLengthUsageDescription: 2000,
            maxLengthAscendingPipesEnds: 200
        },
        buildingComplex: {
            maxLengthName: 200,
            maxLengthRawAddress: 4000,
            maxLengthReference: 200
        },
        company: {
            maxLengthReference: 200,
            maxLengthName: 200
        },
        person: {
            maxLengthFirstName: 50,
            maxLengthLastName: 50,
            maxLengthTitle: 50,
            maxLengthEmail: 50,
            maxLengthPhone: 50,
            maxLengthPhoneMobile: 50
        },
        projects: {
            maxLengthTitle: 100
        }
    }
};
