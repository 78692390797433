import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { ClientErrorMessageDto, DefaultService } from "../../../generated/api";
import { ErrorLogItem } from "../health/error-log-item";
import { SessionService } from "../session/session.service";

/**
 * The API service to report something to the server, like errors.
 */
@Injectable({
    providedIn: "root"
})
export class ReportingApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public async reportErrors(errors: Array<ErrorLogItem>): Promise<void> {
        const errorDtos: Array<ClientErrorMessageDto> = [];
        for (const error of errors) {
            errorDtos.push({
                time: error.time,
                sessionId: `2.26.1, ${error.personId ?? ""}, ${error.deviceId ?? ""}, ${error.sessionId ?? ""}, ${error.activeUrl ?? ""}`,
                code: error.code,
                error: error.error,
                cause: error.innerError
            });
        }

        await lastValueFrom(this.defaultService.createClientErrorMessage(errorDtos));
    }
}
